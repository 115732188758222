import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { Header } from 'components';
import { Layout, Container } from 'layouts';
import { LocalizedLink } from '../components';

const ErrorPage = center => (
  <Layout
    locale={'ja'}
    defaultSlug={''}
    supportedLangs={[]}
  >
    <Helmet title={'404'} />
    <Header title="404" />
    <Container center={center}>
      <h1>Woops, something went wrong.</h1>
      <h3>This page does not exist or is no longer reachable.</h3>
      <h3>
        You can return to the <LocalizedLink to="/">Homepage</LocalizedLink>.
      </h3>
    </Container>
  </Layout>
);

export default ErrorPage;

Container.propTypes = {
  center: PropTypes.object,
};
